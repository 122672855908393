import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

//Ideal Postcodes
//import IdealPostcodesVue from 'ideal-postcodes-vue';
//Vue.use(IdealPostcodesVue, {
//  key: 'ak_kocntxguVrWSb3kW0yysJA8mfrDTm',
//});

//ant-design-vue
import Steps from 'ant-design-vue/lib/steps';
import DatePicker from 'ant-design-vue/lib/date-picker';
import Tag from 'ant-design-vue/lib/tag';
import Collapse from 'ant-design-vue/lib/collapse';
import Spin from 'ant-design-vue/lib/spin';
import Radio from 'ant-design-vue/lib/radio';
import Switch from 'ant-design-vue/lib/switch';
import Select from 'ant-design-vue/lib/select';
import Input from 'ant-design-vue/lib/input';
import Tooltip from 'ant-design-vue/lib/tooltip';
import Descriptions from 'ant-design-vue/lib/descriptions';
import Drawer from 'ant-design-vue/lib/drawer';
import Comment from 'ant-design-vue/lib/comment';
import Dropdown from 'ant-design-vue/lib/dropdown';
import Menu from 'ant-design-vue/lib/menu';
import FormModel from 'ant-design-vue/lib/form-model'
import AutoComplete from 'ant-design-vue/lib/auto-complete'
import Icon from 'ant-design-vue/lib/icon';

import 'ant-design-vue/lib/steps/style/css';
import 'ant-design-vue/lib/date-picker/style/css';
import 'ant-design-vue/lib/tag/style/css';
import 'ant-design-vue/lib/collapse/style/css';
import 'ant-design-vue/lib/spin/style/css';
import 'ant-design-vue/lib/radio/style/css';
import 'ant-design-vue/lib/switch/style/css';
import 'ant-design-vue/lib/select/style/css';
import 'ant-design-vue/lib/input/style/css';
import 'ant-design-vue/lib/tooltip/style/css';
import 'ant-design-vue/lib/descriptions/style/css';
import 'ant-design-vue/lib/drawer/style/css';
import 'ant-design-vue/lib/comment/style/css';
import 'ant-design-vue/lib/dropdown/style/css';
import 'ant-design-vue/lib/menu/style/css';
import 'ant-design-vue/lib/form-model/style/css';
import 'ant-design-vue/lib/auto-complete/style/css';
import 'ant-design-vue/lib/icon/style/css';


Vue.use(Steps);
Vue.use(DatePicker);
Vue.use(Tag);
Vue.use(Collapse);
Vue.use(Spin);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Input);
Vue.use(Tooltip);
Vue.use(Descriptions);
Vue.use(Drawer);
Vue.use(Comment);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(FormModel);
Vue.use(AutoComplete);
Vue.use(Icon);

//VueTabulator
import VueTabulator from 'vue-tabulator';
Vue.use(VueTabulator);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
