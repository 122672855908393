import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Bookings = () => import('@/views/booking/Bookings.vue')
const Courses = () => import('@/views/booking/Courses.vue')
const BookingForm = () => import('@/views/booking/BookingForm.vue')
const Dashboard = () => import('@/views/Dashboard.vue')
const Trainers = () => import('@/views/users/Trainers.vue')
const Reports = () => import('@/views/reports/Reports.vue')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const DelegateExternalForm = () => import('@/views/utils/DelegateExternalForm')

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/login',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    },
    {
      path: '/dashboard',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        { 
          path: '/bookings',
          name: 'Bookings',
          component: Bookings,
        },
        { 
          path: '/courses',
          name: 'Courses',
          component: Courses,
        },
        {
          path: '/booking_form',
          name: 'BookingForm',
          component: BookingForm
        },
        {
          path: '/trainers',
          name: 'Trainers',
          component: Trainers
        },
        {
          path: '/reports',
          name: 'Reports',
          component: Reports
        }
      ]
    },
    {
      path: '/delegates',
      name: 'DelegateExternalForm',
      component: DelegateExternalForm,
    },
  ]
}

